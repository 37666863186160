import { graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';

// import styled from 'styled-components';
import urlBuilder from '@sanity/image-url';
import Seo from '../components/Seo.component';
const urlFor = (source) =>
  urlBuilder({ projectId: 'v3xfey4o', dataset: 'production' }).image(source);

const serializer = {
  types: {
    image: (props) => {
      return (
        <figure>
          <img
            src={urlFor(props.node.asset).url()}
            alt={props.node.alt || 'Hình ảnh'}
          />

          {props.node.caption && <figcaption>{props.node.caption}</figcaption>}
        </figure>
      );
    },
    richText: (props) => {
      if (props.children[0] === '') return null;
      return (
        <div className="flex">
          <span className="mr-1">
            <svg
              className="w-5 h-5 mt-px text-primary"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </span>
          {props.children}
        </div>
      );
    },
  },
};

export default function AwardDetailSingle({ data: { award } }) {
  const input = award._rawDescription;
  return (
    <>
      <Seo title={award.name} image={award?.imageAward?.asset.url} />
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div>
            <GatsbyImage
              image={award.imageAward.asset.gatsbyImageData}
              alt={award.imageAward.asset.originalFilename}
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                {award.name}
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                {award.shortdesc}
              </p>
            </div>
            <p className="mb-4 text-sm font-bold tracking-widest uppercase">
              Chi tiết:
            </p>

            <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
              <div className="space-y-3">
                {input && (
                  <BlockContent blocks={input} serializers={serializer} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context via gatsby-node.js
export const query = graphql`
  query ($slug: String!) {
    award: sanityAward(slug: { current: { eq: $slug } }) {
      id
      name
      shortdesc
      imageAward {
        asset {
          url
          originalFilename
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      _rawDescription
    }
  }
`;
